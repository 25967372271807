import React, { Fragment, memo, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Section, SectionHeader, InjectHtml, Button } from "@uaveiro/ui";
import data from "./data";
import { withTheme } from "styled-components";
import { compose } from "../../../services/utilities";
import { getCoursePresentationById } from "../../../api/requests";
import { ImageOds } from "../../atoms";
import Loading from "./Loading.js";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const SvgWrapper = styled.a`
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  border-radius: ${props => props.borderRadius};
  object-fit: ${props => props.objectFit};
  display: inline-block;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    max-height: 32px;
  }
`;

const MobileMargin = styled.div`
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }
`;

const MobileMarginODS = styled.div`
    margin-Right: 20px;
`;

const CourseSDG = memo(({ theme, match, history }) => {
    const [state, setState] = useState({
        loading: true,
        error: false,
        content: {}
    });

    const language = match.params.lang;
    const title = data[language].title;
    const text = data[language].text;
    const subweb = data[language].subweb;
    const officialUrl = data[language].officialUrl;
    const objectives = data[language].objectives;
    const textElements = text && text.length > 0 ? text.map((html, index) => (
        <InjectHtml key={index} content={html} variant="presentation" />
    )) : [];

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const onFetchContent = async () => {
        try {
            const { lang, id } = match.params;
            const data = await getCoursePresentationById({
            id,
            lang,
            hasDelay: true,
            history
            });

            const {
                data: { payload }
            } = data;
         
            setState(prevState => {
            
            return {
                ...prevState,
                content: { ...data },
                loading: false                
            };
            });
        } catch (error) {
            setState(prevState => {
            return { ...prevState, loading: false, error: true };
            });
        }
    };

    useEffect(() => {
        onFetchContent();        
    }, [history.location]);

    const imageSrc = language === 'pt'
    ? (isHovered ? "/icons/ods/logos.svg#pt-bw" : "/icons/ods/logos.svg#pt-color")
    : (isHovered ? "/icons/ods/logos.svg#en-bw" : "/icons/ods/logos.svg#en-color");

    return (
        <Section id="ods" backgroundColor={theme.highContrast.backgroundColor}>
            <div className="container">
                {state.loading ? (
                    <Loading />
                ) : state.error ? (
                    <Error />
                ) : (
                    state.content.data && state.content.data.ods && state.content.data.ods.length > 0 && (
                        <Fragment>
                            <SectionHeader
                                title={title}
                                textColor={theme.highContrast.text}
                                hasLink={false}
                            />
                            <div className="container">
                                <div className="row justify-content-lg-between align-items-end">
                                    <div className="col-xl col-lg col-md-12">
                                    {   
                                        state.content.data.ods.map(ods => (           
                                            <MobileMarginODS key={ods} className="mb-3 d-inline-block" >                                                       
                                                <ImageOds
                                                language={language}
                                                type="courseSDG"
                                                id={ods}  
                                                link={objectives[ods-1].url}
                                                title={objectives[ods-1].label}
                                                key={ods}
                                                target="_blank"
                                                />  
                                            </MobileMarginODS>                                  
                                        ))
                                    }     
                                    </div>    
                                </div>
                                <div className="row">
                                    <div className="col-xl col-lg col-md-12">
                                        {textElements[0]}
                                    </div>
                                    <div className="col-xl col-lg col-md-12">
                                        <div className="mb-5">
                                            {textElements[1]}
                                        </div>
                                        <div className="row"> 
                                        <MobileMargin className="col-12 col-sm-4 col-md-4 col-xl-4">
                                            <Button width="100%" className="justify-content-center"
                                            href={subweb}
                                            as="a"
                                            display="inline-flex"
                                            target="_blank"
                                            >
                                                <FormattedMessage
                                                id="app.organism.button.knowMore"
                                                defaultMessage="saber mais"
                                                />
                                            </Button>
                                        </MobileMargin>
                                        <MobileMargin className="col-12 col-sm-8 col-md-8 col-xl-6 offset-xl-2 d-flex justify-content-center justify-content-md-end align-items-end">
                                            <SvgWrapper
                                                fontDecoration="none"
                                                href={officialUrl}
                                                native
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                target="_blank"
                                            >
                                                <svg >
                                                    <use href={imageSrc} />
                                                </svg>
                                                
                                            </SvgWrapper>
                                        </MobileMargin>                                                         
                                        </div>                        
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                )}
            </div>
        </Section>
    );
});

export default compose(withRouter, withTheme)(CourseSDG);
