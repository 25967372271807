import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Requite from "requite";

import {
  CoursesSelects,
  SimpleList,
  Information,
  JournalFooter,
  JournalRelated,
  CourseSDG
} from "../../components/organisms";

import {
  Slider,
  Campus,
  Facts,
  OneFact,
  Numbers,
  Agenda,
  Timely,
  Testimonials,
  TestimonialsWithLink,
  Events,
  Presentation,
  Departments,
  News,
  Courses,
  Curricular,
  CurricularPlan,
  Candidature,
  CurricularUnit,
  Contents,
  Contests,
  Edicts,
  Profile,
  Directory,
  GlobalSearch,
  ImageWrapper,
  SiteMap,
  Cards,
  MultiColumns,
  ContentList,
  GoogleSearch,
  Article,
  PeopleArticle,
  SEO,
  JournalNavigation,
  JournalHighlights,
  JournalNews,
  JournalCards,
  JournalColumns,
  JournalListing,
  JournalMoreNews,
  JournalDetails,
  JournalSlides,
  JournalDossiers,
  JournalDossiersDetails,
  Breadcrumbs,
  ProjectsList,
  ProjectDetails,
  ProjectRedirect,
  SubWebPeople,
  SubWebDepartmentCourses,
  SubWebCourses,
  SubWebLinks,
  SubWebDepartment,
  DemoMark,
  SubWebNavigation,
  SubWebContacts,
  SubWebWarnings,
  SubWebSchedule,
  AuthenticationCallback,
  Services,
  SubWebEvents,
  Tables,
  CourseInfo,
  Gallery,
  SubWebVideo,
  SubWebFiles,
  Xperimenta
} from "../../templates";

import Playground from "../../templates/Playground";

//hocs
import { withContext } from "../../hocs";
import { compose } from "../../services/utilities";

const ErrorHandler = () => {
  return <Fragment></Fragment>;
};

const Components = {
  News,
  Campus,
  Facts,
  OneFact,
  Numbers,
  Agenda,
  Timely,
  Testimonials,
  TestimonialsWithLink,
  Slider,
  Events,
  Presentation,
  CourseSDG,
  Departments,
  CoursesSelects: CoursesSelects,
  CourseInfo,
  Curricular,
  Candidature,
  CurricularPlan,
  Contents,
  Contests,
  MultiColumns,
  Image: ImageWrapper,
  CurricularUnit,
  Courses,
  Edicts,
  Profile,
  ContentList,
  Directory,
  Search: GoogleSearch,
  SimpleList: SimpleList,
  Information: Information,
  GlobalSearch,
  Article,
  ArticleDetails: Article,
  PeopleArticle,
  SEO,
  SiteMap,
  Cards,
  JournalNavigation,
  JournalHighlights,
  JournalNews,
  JournalCards,
  JournalMoreNews,
  JournalColumns,
  JournalSlides,
  JournalFooter,
  JournalListing,
  JournalDetails,
  JournalRelated,
  JournalDossiers,
  JournalDossiersDetails,
  Breadcrumbs,
  ProjectsList,
  ProjectDetails,
  ProjectRedirect,
  SubWebNavigation,
  SubWebPeople,
  SubWebContacts,
  SubWebDepartment,
  SubWebWarnings,
  SubWebSchedule,
  SubWebCourses,
  SubWebLinks,
  SubWebDepartmentCourses,
  DemoMark,
  AuthenticationCallback,
  Playground,
  Services,
  SubWebEvents,
  Tables,
  Gallery,
  SubWebVideo,
  SubWebFiles,
  Xperimenta
};

function ComponentSelector({ name, ...props }) {
  const rest = Object.assign({}, props);
  delete rest.index;
  delete rest.length;
  delete rest.context;

  const render = () => {
    const renderComponents = [];
    if (name !== "SideNav") {
      renderComponents.push(
        <Requite
          key={name}
          list={Components}
          type={name}
          error={ErrorHandler}
          {...rest}
        />
      );
    }
    return renderComponents;
  };
  return render();
}

ComponentSelector.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired
};

export default compose(withContext)(ComponentSelector);
